<template>
  <v-container>
    <v-card v-if="expense && Object.keys(expense).length !== 0" color="brown lighten-5">
      <v-card-title>
        <v-icon large>mdi-cash-plus</v-icon>View/Edit Expense
        <v-spacer></v-spacer>
        <v-menu dark>
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>

          <v-list light>
            <v-list-item @click="deleteExpense(expense._id)">
              <v-list-item-title>Delete</v-list-item-title>
            </v-list-item>
            <v-list-item @click="setEditing(true)">
              <v-list-item-title>Edit</v-list-item-title>
            </v-list-item>
            <v-list-item @click="setEditing(false)">
              <v-list-item-title>Print</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-card-title>

      <v-card-text>
        <form v-if="clients.length">
          <v-card class="expense-card">
            <v-card-title>Expense</v-card-title>
            <v-card-text>
              <DateDisplay v-if="expense.date && !loading" :date="expense.date" />

              <p>Expense #: {{expense.expenseNumber}}</p>


              <ShowClientInfo :client="client" />

              <div class="totals">
                <div class="subtotal-line">
                  <div>Subtotal</div>
                  <div class="dotted-line-spacer"></div>
                  <MoneyFormat :value="subTotal / 100"></MoneyFormat>
                </div>
                <div class="tax-line">
                  <div>{{taxRate * 100}}% {{taxLabel}} (#{{taxAccount}})</div>
                  <div class="dotted-line-spacer"></div>
                  <!-- <div>${{taxAmount}}</div> -->
                  <div>
                    <MoneyFormat :value="taxAmount / 100"></MoneyFormat>
                  </div>
                </div>
                <div class="total-line">
                  <div>Total</div>
                  <div class="dotted-line-spacer"></div>
                  <MoneyFormat :value="totalAmount / 100" locale="en" currency-code="CAD"></MoneyFormat>
                </div>
              </div>

              <p class="terms">
                <span v-if="prefs.expenseTerms">{{prefs.expenseTerms}}</span>
                <span v-if="prefs.paymentMethods">{{prefs.paymentMethods}}</span>
              </p>
            </v-card-text>
            <v-card-actions v-if="editing">
              <v-spacer></v-spacer>
              <v-btn small dark color="brown" @click="save">Save</v-btn>
            </v-card-actions>
          </v-card>
        </form>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
// import { validationMixin } from "vuelidate";
// import { required } from "vuelidate/lib/validators";
// import { DateTime } from "luxon";
import MoneyFormat from "vue-money-format";
import DateDisplay from "@/components/expense/DateDisplay";
import ShowClientInfo from "@/components/client/ShowClientInfo";

export default {
  //   mixins: [validationMixin],

  //   validations: {
  //     select: { required },
  //   },
  components: {
    MoneyFormat,
    DateDisplay,
    ShowClientInfo,
  },
  data() {
    return {
      itemCount: 0,
      select: null,
      taxRate: 0,
      taxLabel: "",
      taxAccount: "",
      statusOptions: ["sent", "received", "draft"],
      hourlyRate: 0,
      dialog: false,
    };
  },
  computed: {
    editing() {
      return this.$store.getters.editing;
    },
    loading() {
      return this.$store.state.loading;
    },
    expense() {
      return this.$store.getters.expense;
    },
    items() {
      return this.expense.items;
    },
    subTotal() {
      let st = 0;
      if (this.items) {
        this.items.forEach((item) => {
          st = st + item.amount;
        });
      }
      return st;
    },
    taxAmount() {
      let ta = 0;
      if (this.taxRate > 0) {
        ta = this.taxRate * this.subTotal;
      }
      return ta;
    },
    totalAmount() {
      let total = 0;
      if (this.subTotal > 0) {
        total = Math.round(this.subTotal + this.taxAmount);
      }
      return total;
    },
    prefs() {
      return this.$store.getters.preferences;
    },
    clients() {
      return this.$store.getters.sortedClients;
    },
    client() {
      let index = this.clients.findIndex(
        (client) => client._id == this.expense.client
      );
      let client = this.clients[index];
      if (client) {
        return client;
      } else {
        return {};
      }
    },
    // selectErrors() {
    //   const errors = [];
    //   if (!this.$v.select.$dirty) return errors;
    //   !this.$v.select.required && errors.push("Client is required");
    //   return errors;
    // },

    // descriptionErrors() {
    //   const errors = [];
    //   if (!this.$v.name.$dirty) return errors;
    //   !this.$v.name.maxLength &&
    //     errors.push("Description must be at most 255 characters long");
    //   return errors;
    // },
  },

  methods: {
    setEditing(v) {
      this.$store.dispatch("setEditing", v);
    },
    printExpense() {
      alert("print the expense functionality coming soon");
    },
    deleteExpense(id) {
      this.$store.dispatch("deleteExpense", id);
    },
    save() {
      let payload = {
        _id: this.expense._id,
        items: this.items,
        amount: this.subTotal,
        taxRate: this.taxRate,
        tax: this.taxAmount,
        totalAmount: this.totalAmount,
        date: this.expense.date,
      };
      this.$store.dispatch("updateExpense", payload);
    },
    clear() {
      //   this.$v.$reset();
      this.name = "";
      this.email = "";
      this.select = null;
    },
  },
  mounted() {
    this.$store.dispatch("loadExpense", this.$route.params.id);
    this.$store.dispatch("loadClients");
    this.select = this.expense.client;
    this.taxRate = this.prefs.taxRate || 0;
    this.hourlyRate = this.prefs.hourlyRate || 0;
    this.taxLabel = this.prefs.taxLabel || "";
    this.taxAccount = this.prefs.taxAccount || "";
  },
};
</script>

<style scoped>
.totals {
    margin-top: 60px;
    margin-bottom: 100px;
}
.status-draft {
  color: orange;
}
.client-select {
  max-width: 300px;
}
.expense-card {
  margin-left: auto;
  margin-right: auto;
  max-width: 1200px;
  min-height: 1200px;
  padding-left: 100px;
  padding-right: 100px;
  padding-top: 50px;
  padding-bottom: 50px;
}
.clock {
  font-family: monospace;
  color: gray;
}
.dotted-line-spacer {
  border-bottom: dotted 1px gray;
  flex-grow: 1;
  align-content: flex-end;
}
.subtotal-line,
.tax-line,
.total-line {
  display: flex;
  flex-wrap: wrap;
  margin-left: 100px;
  margin-right: 100px;
}
.subtotal-line,
.total-line {
  font-weight: bold;
  margin-top: 20px;
}

.total-line {
  font-size: larger;
  margin-top: 60px;
}
.tax-line {
  margin-top: 20px;
}
.terms {
  margin-top: 40px;
  margin-left: 100px;
  margin-right: 100px;
}
.payment-methods {
  margin-left: 100px;
  margin-right: 100px;
}
</style>